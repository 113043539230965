import _ from 'underscore';
import $ from 'jquery';
import 'backbone.stickit';
import pupa from 'pupa';
import ContainerView from '../view/container-view';
import LabelView from '../view/label-view';
import FieldInputView from '../view/field-input-view';
import FieldSelectView from '../view/field-select-view';
import RadioView from '../view/field-radio-view';
import ButtonView from '../view/button-view';
import Backbone from "backbone";

export default ContainerView.extend({

  template: undefined,

  /**
   * @param {object} options
   * @param {string} options.templateStr
   */
  initialize: function (options) {
    ContainerView.prototype.initialize.call(this, arguments);
    this.template = _.template(options.templateStr);
  },

  render: function () {
    this.removeChildViews();

    this.$el.html(this.template({}));
    const childViews = this.childViews;
    const collection = this.collection;

    const elements = this.$el.find('[md-id]');
    elements.each(function (idx, elem) {
      const $el = $(elem);
      const models = ($el.attr('md-id') || '').split(',').map(id => id.trim()).map(id => collection.get(id));
      const model = _.first(models);

      if (_.some(models, model => !model)) {
        console.warn(pupa('Some model not found for tag {0} with md-id {1}. Child view not bound.', [elem.tagName, $el.attr('md-id')]));
      } else {
        if (this.tagName === 'rg-select'.toUpperCase())
          childViews.push(new FieldSelectView({
            model: model,
            el: this,
            label: this.getAttribute('label')
          }));
        else if ((this.tagName === 'rg-input'.toUpperCase()))
          childViews.push(new FieldInputView({
            model: model,
            el: this,
            type: this.getAttribute('type'),
            label: this.getAttribute('label'),
            min: this.getAttribute('min'),
          }));
        else if ((this.tagName === 'rg-label'.toUpperCase()))
          childViews.push(new LabelView({
            collection: new Backbone.Collection(models),
            el: this,
            format: this.getAttribute('format'),
            formatExpr: this.getAttribute('format-expr')
          }));
        else if ((this.tagName === 'rg-radio'.toUpperCase()))
          childViews.push(new RadioView({
            model: model,
            el: this,
            label: this.getAttribute('label'),
          }));
        else if ((this.tagName === 'rg-button'.toUpperCase()))
          childViews.push(new ButtonView({
            model: model,
            el: this
          }));
      }
    });

    _.each(childViews, function (view) {
      view.render()
    });

    this.$el.localize();
    return this;
  }

});
