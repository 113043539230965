import i18next from 'i18next';
import _ from "underscore";
import Backbone from 'backbone';
import 'backbone.stickit';

export default Backbone.View.extend({

  /**
   * @type {string}
   */
  type: undefined,
  /**
   * @type {string}
   */
  label: undefined,

  /**
   * @type {string}
   */
  min: undefined,

  template: _.template(require('./field-input-view.tmpl.html')),

  bindings: {
    input: {
      observe: 'value',
      events: ['blur'],
      onSet: function (value) {
        if (this.type === 'number') {
          return (value ? Number(value) : null);
        }
        return value;
      },
      attributes: []
    },
    label: {
      observe: 'label',
      onGet: function (value) {
        return i18next.t(value || this.label);
      },
      update: function ($el, val) {
        $el.html(val);
      }
    }
  },

  initialize: function (args) {
    _.extend(this, _.pick(args, ['type', 'label', 'min']));
  },

  render: function () {
    this.$el.html(this.template({type: this.type}));
    this.stickit();
    if (_.isString(this.min)) {
      const newBinding = _.clone(this.bindings.input);
      newBinding.attributes.push({
        name: 'min',
        onGet: function () {
          return this.min;
        }
      });
      this.addBinding(null, 'input', newBinding);
    }
    return this;
  },

  remove: function () {
    this.unstickit();
    Backbone.View.prototype.remove.call(this);
  },

});
