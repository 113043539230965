const maritalState = Object.freeze({
  Single: "single",
  Married: "married",
  Divorced: "divorced",
  Separated: "separated",
  Widowed: "widowed",
  RegPartnership: "reg-ps",
  RegPartnershipDivorced: "rp-divorced",
  RegPartnershipSeparated: "rp-separated",
  RegPartnershipWidowed: "rp-widowed",
})

export {
  maritalState
}