import NpTaxCalcSvc from './np-taxcalc-svc';
import services from "./services";
import $ from "jquery";

export default class NpSzTaxCalcSvc extends NpTaxCalcSvc {

  /**
   * @typedef {Object} NpSzCalculatorMetadata
   *
   * @property {Array.<number>} supportedTaxYears the supported tax years
   * @property {Array.<{key: string, value: string }>} taxMunicipalities
   */

  /**
   * Return a Promise which resolves the metadata
   *
   * @returns {Promise<NpSzCalculatorMetadata>} the metadata
   */
  metadata() {
    return super.metadata('np-sz');
  }


  /**
   * Returns a promise which resolves the calculation of income, wealth and church tax
   *
   * @param {Object} options
   * @param {number} options.taxYear the tax year
   */
  calculateIncomeWealthChurchTax(options) {
    const self = this;
    return new Promise(
        function (resolve, reject) {
          services.auth.getCurrentUserIdToken().then(function (token) {
            $.ajax({
              url: self.rootPath + '/taxcalc/np/sz/incomeWealthChurchTax',
              contentType: 'application/json',
              type: 'POST',
              cache: false,
              headers: {
                "Authorization": 'Bearer ' + token
              },
              timeout: services.getRequestTimeOut(),
              data: JSON.stringify(options),
              success: function (data) {
                resolve(data);
              },
              error: function (xhr) {
                reject(xhr);
              },
            });
          }, reject)
        }
    );
  }
}
