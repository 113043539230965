import _ from 'underscore';
import Backbone from 'backbone';
import numeral from 'numeral';
import PQueue from "p-queue";
import DefaultController from "./default-controller";
import RgTemplateView from './../comp-view/rg-template-view';
import services from './../service/services';
import {maritalState} from './common';
import ComputedFieldsUtils from "../model/computed-fields-utils";

export default class NpFederalTaxCalcController extends DefaultController {

  constructor() {
    super();

    /**
     * @type {Backbone.Collection}
     */
    this.modelCol = new Backbone.Collection();

    /**
     * @type Backbone.View
     */
    this.view = undefined;

    this.uiModelSetOptions = {updateMode: 'controller'};

    this.calcModel = new (Backbone.Model.extend({
      initialize: function () {
        _.extend(this, ComputedFieldsUtils);
        this.createComputedDefaultValue('rateDeterminingIncome', 'taxableIncome');
        this.computedFields = new Backbone.ComputedFields(this);
      },
      defaults: {
        'maritalState': maritalState.Single
      },
      computed: {
        couple: {
          depends: ['maritalState'],
          get: function (fields) {
            switch (fields.maritalState) {
              case maritalState.Single:
                return false;
              case maritalState.Married:
              case maritalState.RegPartnership:
                return true;
              default:
                return undefined;
            }
          }
        }
      }
    }))();

    /**
     * @type {Array<string>}
     */
    this.inputModelIds = ['taxYear', 'taxableIncome', 'rateDeterminingIncome', 'maritalState', 'childAndSupportedPersonCount'];

    /**
     * @type {Array<string>}
     */
    this.resultModelIds = ['taxableIncomeRounded', 'rateDeterminingIncomeRounded', 'tariff', 'incomeTaxRate', 'incomeTax',
      'incomeTaxReductionChildSuppPersCount', 'incomeTaxReductionChildSuppPers', 'taxTotal'];

    /**
     * Initial model attributes for print-action
     * @type {{icon: undefined, id: string, state: string, text: string, href: undefined, target: undefined}}
     */
    this.printActionInitialAttr = {
      id: 'print-action',
      state: 'print-request',
      text: 'print-button.print.text',
      target: undefined,
      href: undefined,
      icon: 'fas fa-print',
    };

    this.pqueue = new PQueue({concurrency: 1});
    _.extend(this, Backbone.Events);
  }

  init(options) {
    this.modelCol.add(_.map(_.union(this.inputModelIds, this.resultModelIds), function (id) {
      return new Backbone.Model({id: id, value: null});
    }));
    this.modelCol.add(new Backbone.Model({
      id: 'print-format',
      value: 'pdf',
      selectOptions: [
        {value: 'pdf', label: 'PDF'},
        {value: 'docx', label: 'Word'}
      ]
    }));
    this.modelCol.add(new Backbone.Model({
      id: 'print-lng',
      value: 'de',
      selectOptions: [
        {value: 'de', label: 'Deutsch'},
        {value: 'en', label: 'English'}
      ]
    }));
    this.modelCol.add(new Backbone.Model(this.printActionInitialAttr));

    _.each(['taxYear', 'maritalState'], id => this.modelCol.get(id).set('selectOptions', []), this);

    this.view = new RgTemplateView({
      collection: this.modelCol,
      templateStr: require('./../comp-view/np-federal-taxcalc-view.tmpl.html')
    });

    return super.init();
  }

  start(options) {
    this.modelCol.get('maritalState').set({
      selectOptions: [
        {value: maritalState.Single, label: "Alleinstehend"},
        {value: maritalState.Married, label: "Verheiratet"},
        {value: maritalState.RegPartnership, label: "Registrierte Partnerschaft"}
      ]
    });
    this.updateView(this.calcModel.attributes);

    /** @type NpFederalTaxCalcSvc */
    const npFederalCalculatorSvc = services.calculators['np-federal'];
    npFederalCalculatorSvc.metadata().then(_.bind((metadata) => {
          this.calcModel.set('taxYear', _.first(metadata.supportedTaxYears) || null);
          this.modelCol.get('taxYear').set({
            selectOptions: _.map(metadata.supportedTaxYears, taxYear => {
              return {value: taxYear, label: taxYear.toString()}
            })
          }, this.uiModelSetOptions);

          this.updateView(this.calcModel.attributes);
          this._calculate();
        }, this)
    ).catch((error) =>
        console.error('np federal tax calculation failed', error)
    )

    this.listenTo(this.modelCol, 'change', function (model, args) {
      const inputModelUpdated = _.some(this.inputModelIds, function (id) {
        return model && model.id === id;
      });
      if (inputModelUpdated)
        this.onInputModelChange.apply(this, arguments);
      else if (model.id === 'print-format' || model.id === 'print-lng') {
        // print-options changed, reset print-action
        this.modelCol.get('print-action').set(this.printActionInitialAttr);
      }
    });

    const printActionModel = this.modelCol.get('print-action');
    this.listenTo(printActionModel, 'action', function () {
      if (printActionModel.get('state') !== 'print-request')
        return;
      this.print();
    });

    this.view.render();
    return super.start();
  }

  updateView(attributes) {
    if (attributes) {
      _.each(_.keys(attributes), function (key) {
        const uiModel = this.modelCol.get(key);
        if (uiModel)
          uiModel.set({value: attributes[key]}, this.uiModelSetOptions);
      }, this);
    }
  }

  onInputModelChange(model, args) {
    // don't handle self triggered events (see default value below)
    if (_.isObject(args) && args.updateMode === this.uiModelSetOptions.updateMode)
      return;

    const id = model.get('id');
    this.calcModel.set(id, model.get('value'));

    this.updateView(this.calcModel.changedAttributes());

    this._calculate();
  }

  _calculate() {
    function undefinedToNull(val) {
      if (_.isUndefined(val))
        return null;
      return val;
    }

    const calcOptions = {
      taxYear: this.calcModel.get('taxYear'),
      couple: this.calcModel.get('couple'),
      taxableIncome: undefinedToNull(this.calcModel.get('taxableIncome')),
      rateDeterminingIncome: undefinedToNull(this.calcModel.get('rateDeterminingIncome')),
      childAndSupportedPersonCount: undefinedToNull(this.calcModel.get('childAndSupportedPersonCount'))
    };

    /** @type NpFederalTaxCalcSvc */
    const npFederalCalculatorSvc = services.calculators['np-federal'];
    this.pqueue.add(() => npFederalCalculatorSvc.calculate(calcOptions))
        .then(_.bind((data) => {
              data = data || {};

              const updates = {};
              _.each(this.resultModelIds, function (id) {
                // exclude computed properties without setter
                if (!_.has(this.calcModel.computed, id) || _.has(this.calcModel.computed[id], 'set'))
                  updates[id] = null;
              }, this);

              _.each(data.results, function (result) {
                switch (result.name) {
                  case "tariff":
                    updates.tariff = result.value ? 'federalTariff.' + result.value + '.label' : null;
                    updates.tariffRaw = result.value;
                    break;
                  case "taxableIncomeRounded":
                    updates.taxableIncomeRounded = result.value;
                    break;
                  case "rateDeterminingIncomeRounded":
                    updates.rateDeterminingIncomeRounded = result.value;
                    break;
                  case "incomeTax":
                    updates.incomeTax = result.value;
                    updates.incomeTaxRate = _.get(result, ['details', 'rate'], null);
                    break;
                  case "incomeTaxReductionChildSuppPers":
                    updates.incomeTaxReductionChildSuppPers = result.value;
                    updates.incomeTaxReductionChildSuppPersCount = _.get(result, ['details', 'count'], null);
                    break;
                  case "taxTotal":
                    updates.taxTotal = result.value;
                    break;
                }
              }, this);
              this.calcModel.set(updates);
              this.updateView(this.calcModel.changedAttributes());

              this.modelCol.get('print-action').set(this.printActionInitialAttr);
            }, this)
        ).catch((error) =>
        console.error('np federal tax calculation failed', error)
    );
  }

  print() {
    const printActionModel = this.modelCol.get('print-action');

    printActionModel.set({
      state: 'print-wait',
      text: 'print-button.printing.text',
      icon: 'fas fa-spinner fa-pulse has-text-primary'
    }, this.uiModelSetOptions);
    const exportFormatDocx = this.modelCol.get('print-format').get('value') === 'docx';

    const printOptions = {
      exportFormat: exportFormatDocx ? "application/vnd.openxmlformats-officedocument.wordprocessingml.document" : "application/pdf",
      language: this.modelCol.get('print-lng').get('value'),
      report: 'calc.np-federal',
      datasource: {
        taxYear: this.calcModel.get('taxYear'),
        taxableIncome: this.calcModel.get('taxableIncome'),
        rateDeterminingIncome: this.calcModel.get('rateDeterminingIncome'),
        childAndSupportedPersonCount: this.calcModel.get('childAndSupportedPersonCount'),
        maritalState: this.calcModel.get('maritalState'),
        tariff: this.calcModel.get('tariffRaw'),
        "results": [
          {
            "description": "calc.np-federal.description.income",
            "taxable": this.calcModel.get('taxableIncomeRounded'),
            "rateDetermining": this.calcModel.get('rateDeterminingIncomeRounded'),
            "rate": _.isNumber(this.calcModel.get('incomeTaxRate')) ?
                numeral(this.calcModel.get('incomeTaxRate')).format('0.00000 %') : null,
            "tax": this.calcModel.get('incomeTax'),
          },
          {
            "description": "calc.np-federal.description.reductionChildSuppPers",
            "taxable": null,
            "rateDetermining": null,
            "rate": this.calcModel.get('incomeTaxReductionChildSuppPersCount'),
            "tax": _.isNumber(this.calcModel.get('incomeTaxReductionChildSuppPers')) ?
                this.calcModel.get('incomeTaxReductionChildSuppPers') * -1 : null
          }
        ],
        "taxTotal": this.calcModel.get('taxTotal')
      }
    };

    this.pqueue.add(() => services.printSvc.print(printOptions))
        .then((blob) => {
          const fileRef = firebase.storage().ref().child(blob);

          // Get the download URL
          fileRef.getDownloadURL().then(function (url) {
            printActionModel.set({
              state: 'print-download',
              href: url,
              icon: exportFormatDocx ? 'far fa-file-word has-text-link' : 'far fa-file-pdf has-text-danger',
              text: 'print-button.download.text',
              target: '_blank'
            }, self.uiModelSetOptions);
          }).catch(error => {
            console.error('Get download url failed', error);
            printActionModel.set(self.printActionInitialAttr);
          })
        }, error => {
          console.error('Print job failed', error);
          printActionModel.set(self.printActionInitialAttr);
        });
  }

  stop(options) {
    this.stopListening(this.modelCol);
    return super.stop();
  }

  destroy(options) {
    this.view.remove();
    return super.destroy();
  }

}
