import $ from "jquery";
import _ from 'underscore';
import cherrytree from 'cherrytree';
import pSeries from 'p-series';

import services from '../service/services';
import ApplicationController from "./application-controller";
import LoginController from './login-controller';
import NpFederalTaxCalcController from "./np-federal-taxcalc-controller";
import NpSzTaxCalcController from "./np-sz-taxcalc-controller";

const createCtRouter = function () {

  const router = cherrytree({log: false});

  router.map(function (route) {
    route('application', {path: '/', abstract: true}, function () {
      route('login')
      route('logout')
      route('calculatorNpFederal', {path: 'calculator/np/federal'})
      route('calculatorNpSz', {path: 'calculator/np/sz'})
    });
  });

  const notAuthorizedRouteNames = new Set(['application', 'login', 'logout']);

  function isAuthorizedRoute(name) {
    return !notAuthorizedRouteNames.has(name);
  }

  const controllers = {
    application: new ApplicationController(),
    login: () => new LoginController(),
    calculatorNpFederal: () => new NpFederalTaxCalcController(),
    calculatorNpSz: () => new NpSzTaxCalcController()
  }

  let applicationControllerStarted = false;

  function resolveController(name) {
    const controller = controllers[name];
    return _.isFunction(controller) ? controller() : controller;
  }

  router.use(function defaultRoute(transition) {
    if (_.size(transition.routes) == 0)
      transition.redirectTo('calculatorNpFederal');
  });

  router.use(function authorization(transition) {
    let authorizedRoute = false, logout = false;
    transition.routes.forEach(function (route, i) {
      if (isAuthorizedRoute(route.name))
        authorizedRoute = true;
      if (route.name === 'logout')
        logout = true;
    });

    if (authorizedRoute && services.userModel.get('authenticated') !== true) {
      return router.transitionTo('login', undefined, {returnUrl: encodeURIComponent(transition.path)});
    } else if (logout) {
      return firebase.auth().signOut().then(function () {
        router.transitionTo('/#');
      }).catch(function (error) {
        console.log('logout failed.', error);
      });
    }
  });

  router.use(function init(transition) {
    // transition.routes.forEach(function (route, i) {
    //   route.view = handlers[route.name]({
    //     params: transition.params,
    //     query: transition.query
    //   })
    //   var parent = transition.routes[i-1]
    //   var containerEl = parent ? parent.view.el.querySelector('.outlet') : document.body
    //   containerEl.appendChild(view.render().el)
    // })
    const tasks = [];
    transition.routes.forEach(function (route, i) {
      route.controller = resolveController(route.name);
      if (i === 0) {
        if (!applicationControllerStarted)
          tasks.push(() => route.controller.init({bodyEl: document.body, router: router}));
      } else {
        tasks.push(() => route.controller.init());
      }
    });
    return pSeries(tasks);
  });

  router.use(function start(transition) {
    const tasks = [];
    transition.routes.forEach(function (route, i) {
      const parent = transition.routes[i - 1]
      const containerEl = parent ? parent.controller.getViewEl().querySelector('.outlet') : document.body

      if (i === 0) {
        if (!applicationControllerStarted)
          tasks.push(() => route.controller.start().then(() => applicationControllerStarted = true));
      } else {
        tasks.push(() => route.controller.start());
        tasks.push(() => $(containerEl).html(route.controller.getViewEl()));
      }
    });
    return pSeries(tasks);
  });

  router.use(function stopDestroy(transition) {
    const tasks = [];
    _.each(transition.prev.routes, function (route, index) {
      if (index > 0) {
        tasks.unshift(() => route.controller.destroy());
        tasks.unshift(() => route.controller.stop());
      }
    });
    return pSeries(tasks);
  });

  router.use(function errorHandler(transition) {
    transition.catch(function (err) {
      if (err.type !== 'TransitionCancelled' && err.type !== 'TransitionRedirected') {
        console.error(err.stack)
      }
    })
  });

  return router;
}

export default createCtRouter;
