import _ from 'underscore';
import Backbone from 'backbone';
import numeral from 'numeral';
import i18next from "i18next";
import SimpleExprEvaluator from "./simple-expr-evaluator";

export default Backbone.View.extend({

  /**
   * @type {string}
   */
  format: undefined,

  /** @type {string} */
  formatExpr: undefined,

  /** @type {SimpleExprEvaluator} */
  _exprEvaluator: undefined,

  tagName: 'span',

  initialize: function (args) {
    _.extend(this, _.pick(args, ['format', 'formatExpr']));

    if (this.formatExpr) {
      this._exprEvaluator = new SimpleExprEvaluator();
      this._exprEvaluator.setExpressionStr(this.formatExpr);
    }

    this.listenTo(this.collection, 'change', this.render);
  },

  render: function () {
    let text;
    if (this._exprEvaluator) {
      text = this._exprEvaluator.eval.apply(this._exprEvaluator, this.collection.map(m => m.get('value')));
    } else {
      const value = text = this.collection.first().get('value');
      if (_.isNumber(value) && this.format) {
        try {
          text = numeral(value).format(this.format);
        } catch (error) {
          console.error('failed to format value', error);
        }
      } else {
        if (text)
          text = i18next.t(text);
      }
    }

    this.$el.html(text || '');
    return this;
  }

});
