import _ from "underscore";

const ComputedFieldsUtils = {

  /**
   * Creates a new computed default value declaration inside of the extended Backbone.Model
   *
   * @param {string} valueKey the name of the value which should get a default value
   * @param {string} defaultKey the name of the referenced default value
   * @param {boolean} initDefaultValueActive if true the default value will be active
   */
  createComputedDefaultValue: function (valueKey, defaultKey, initDefaultValueActive = true) {
    const valueKeyDefault = valueKey + '.default'; // boolean true if computed value follow the default value, otherwise false
    const valueKeyValue = valueKey + '.value'; // the value which is entered by user

    // Using the standard backbone model defaults property for initializing the flag that the default value is active can not used after model is created.
    // So we set the flag with the normal set function.
    if (initDefaultValueActive === true)
      this.set(valueKeyDefault, true);

    this.computed[valueKey] = {
      depends: [defaultKey, valueKeyDefault, valueKeyValue],
      get: function (fields) {
        return fields[valueKeyDefault] ? fields[defaultKey] : fields[valueKeyValue];
      },
      set: function (value, fields) {
        if (_.isNull(value)) {
          fields[valueKeyDefault] = true;
          fields[valueKeyValue] = null;
        } else {
          fields[valueKeyDefault] = false;
          fields[valueKeyValue] = value;
        }
      }
    }
  },

  createComputedTotal: function (totalKey, ...summandKeys) {
    this.computed[totalKey] = {
      depends: summandKeys,
      get: function (fields) {
        const numbers = _.filter(_.values(fields), val => _.isNumber(val));
        const result = _.reduce(numbers, function (memo, num) {
          return memo + num;
        }, 0);
        return numbers.length === 0 ? null : result;
      }
    }
  }
}

export default ComputedFieldsUtils;