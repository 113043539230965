import $ from 'jquery';
import App from './app';

$('#version').html('Version ' + process.env.appVersion + ', Build time ' + process.env.buildTime);

const app = new App();
app.initialize({
  serviceRootPathFunctions: 'https://europe-west6-polished-leaf-295806.cloudfunctions.net', // '/services'
  serviceRootPathPrintSvc: 'https://svc.ralf-schoenrock.ch'// for rewrite to cloud run svc ''
}).then(() => {
  return app.start();
}).catch((error) => {
  console.error('app start sequence is failed', error);
});

