import $ from 'jquery';
import NpTaxCalcSvc from './np-taxcalc-svc';
import services from "./services";

export default class NpFederalTaxCalcSvc extends NpTaxCalcSvc {

  calculate(options) {
    const self = this;
    return new Promise(
        function (resolve, reject) {
          services.auth.getCurrentUserIdToken().then(function (token) {
            $.ajax({
              url: self.rootPath + '/taxcalc/np/federalIncomeTax',
              contentType: 'application/json',
              type: 'POST',
              cache: false,
              headers: {
                "Authorization": 'Bearer ' + token
              },
              timeout: services.getRequestTimeOut(),
              data: JSON.stringify(options),
              success: function (data) {
                resolve(data);
              },
              error: function (xhr) {
                reject(xhr);
              },
            });
          }, reject)
        }
    );
  }

  /**
   * Return a Promise which resolves the metadata
   *
   * @returns {Promise<{supportedTaxYears: Array.<number>}>} the metadata
   */
  metadata() {
    return super.metadata('np-federal');
  }
}
