import _ from 'underscore';

export default function (numeral) {

  numeral.register('locale', 'de-CH', {
    delimiters: {
      thousands: "'",
      decimal: '.'
    },
    abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
    },
    ordinal: function (number) {
      return '.';
    },
    currency: {
      symbol: 'CHF'
    }
  });

  numeral.register('format', 'negative', {
    regexps: {
      format: /^(-)/,
      unformat: /^(-)/
    },
    format: function (value, format, roundingFunction) {
      let output;

      if (_.isNumber(value))
        value *= -1;
      format = format.replace(/^(-)/, '');

      output = numeral._.numberToFormat(value, format, roundingFunction);
      return output;
    },
    unformat: function (string) {
      let output = numeral._.stringToNumber(string);
      if (_.isNumber(output))
        output *= -1;
      return output;
    }
  });

};
