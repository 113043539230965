import Backbone from 'backbone';
import 'backbone-computedfields';
import DefaultController from "./default-controller";
import RgTemplateView from './../comp-view/rg-template-view';
import _ from "underscore";
import {maritalState} from "./common";
import ComputedFieldsUtils from "../model/computed-fields-utils";
import services from "../service/services";
import PQueue from "p-queue";

export default class NpSzTaxCalcController extends DefaultController {

  constructor() {
    super();

    /** @type {Backbone.Collection} */
    this.modelCol = new Backbone.Collection();

    /** @type Backbone.View */
    this.view = undefined;

    this.uiModelSetOptions = {updateMode: 'controller'};

    this.calcModel = new (Backbone.Model.extend({
      initialize: function () {
        _.extend(this, ComputedFieldsUtils);

        this.createComputedDefaultValue('rateDeterminingIncomeCantonal', 'taxableIncomeCantonal');
        this.createComputedDefaultValue('rateDeterminingAssetCantonal', 'taxableAssetCantonal');
        this.createComputedDefaultValue('rateDeterminingIncomeFederal', 'taxableIncomeFederal');

        this.createComputedTotal('cantonalTax', 'cantonalIncomeTax', 'cantonalAssetTax');
        this.createComputedTotal('districtTax', 'districtIncomeTax', 'districtAssetTax');
        this.createComputedTotal('municipalityTax', 'municipalityIncomeTax', 'municipalityAssetTax');
        this.createComputedTotal('romCathChurchTax', 'romCathChurchIncomeTax', 'romCathChurchAssetTax');
        this.createComputedTotal('evRefChurchTax', 'evRefChurchIncomeTax', 'evRefChurchAssetTax');
        this.createComputedTotal('taxTotal', 'totalTaxCantonal', 'taxTotalFederal');

        this.computedFields = new Backbone.ComputedFields(this);
      },
      defaults: {
        'maritalState': maritalState.Single
      },
      computed: {
        couple: {
          depends: ['maritalState'],
          get: function (fields) {
            switch (fields.maritalState) {
              case maritalState.Single:
                return false;
              case maritalState.Married:
              case maritalState.RegPartnership:
                return true;
              default:
                return undefined;
            }
          }
        }
      }
    }))();

    this.inputModelIds = ['taxYear', 'taxMunicipality', 'maritalState', 'childAndSupportedPersonCount', 'confRomCathCount', 'confEvRefCount',
      'confOtherNoneCount', 'taxableIncomeCantonal', 'rateDeterminingIncomeCantonal', 'taxableAssetCantonal',
      'rateDeterminingAssetCantonal', 'taxableIncomeFederal', 'rateDeterminingIncomeFederal'];

    this.resultModelIds = ['taxableIncomeRoundedCantonal', 'rateDeterminingIncomeRoundedCantonal', 'simpleIncomeTaxRateCantonal', 'simpleIncomeTaxCantonal',
      'simpleIncomeTaxRateRegular', 'simpleIncomeTaxRegular',
      'taxableAssetRoundedCantonal', 'rateDeterminingAssetRoundedCantonal', 'simpleAssetTaxRateCantonal', 'simpleAssetTaxCantonal',
      'simpleTaxCantonal', 'simpleTaxRegular',
      'cantonalTaxRate', 'cantonalIncomeTax', 'cantonalAssetTax', 'cantonalTax',
      'districtTaxRate', 'districtIncomeTax', 'districtAssetTax', 'districtTax',
      'municipalityTaxRate', 'municipalityIncomeTax', 'municipalityAssetTax', 'municipalityTax',
      'romCathChurchIncomeTax', 'romCathChurchAssetTax', 'romCathChurchTaxRate', 'romCathChurchTax',
      'evRefChurchIncomeTax', 'evRefChurchAssetTax', 'evRefChurchTaxRate', 'evRefChurchTax',
      'totalTaxCantonal',
      'taxableIncomeRoundedFederal', 'rateDeterminingIncomeRoundedFederal', 'incomeTaxRateFederal', 'incomeTaxFederal',
      'incomeTaxReductionChildSuppPersCount', 'incomeTaxReductionChildSuppPers', 'taxTotalFederal',
      'taxTotal'
    ];

    /**
     * Initial model attributes for print-action
     * @type {{icon: undefined, id: string, state: string, text: string, href: undefined, target: undefined}}
     */
    this.printActionInitialAttr = {
      id: 'print-action',
      state: 'print-request',
      text: 'print-button.print.text',
      target: undefined,
      href: undefined,
      icon: 'fas fa-print',
    };

    this.pqueue = new PQueue({concurrency: 1});
    _.extend(this, Backbone.Events);
  }

  init() {
    this.modelCol.add(_.map(_.union(this.inputModelIds, this.resultModelIds), function (id) {
      return new Backbone.Model({id: id, value: null});
    }));

    _.each(['taxYear', 'taxMunicipality', 'maritalState'], id => this.modelCol.get(id).set('selectOptions', []), this);

    this.modelCol.add(new Backbone.Model({
      id: 'print-format',
      value: 'pdf',
      selectOptions: [
        {value: 'pdf', label: 'PDF'},
        {value: 'docx', label: 'Word'},
        {value: 'xlsx', label: 'Excel'}
      ]
    }));
    this.modelCol.add(new Backbone.Model({
      id: 'print-lng',
      value: 'de',
      selectOptions: [
        {value: 'de', label: 'Deutsch'},
        {value: 'en', label: 'English'}
      ]
    }));
    this.modelCol.add(new Backbone.Model(this.printActionInitialAttr));

    this.view = new RgTemplateView({
      collection: this.modelCol,
      templateStr: require('./../comp-view/np-sz-taxcalc-view.tmpl.html')
    });
    return super.init();
  }

  start() {
    this.modelCol.get('maritalState').set({
      selectOptions: [
        {value: maritalState.Single, label: "Alleinstehend"},
        {value: maritalState.Married, label: "Verheiratet"},
        {value: maritalState.RegPartnership, label: "Registrierte Partnerschaft"}
      ]
    });
    this.updateView(this.calcModel.attributes);

    /** @type NpSzTaxCalcSvc */
    const npSzCalculatorSvc = services.calculators['np-sz'];
    npSzCalculatorSvc.metadata().then(_.bind((metadata) => {
          this.calcModel.set('taxYear', _.first(metadata.supportedTaxYears) || null);
          this.modelCol.get('taxYear').set({
            selectOptions: _.map(metadata.supportedTaxYears, taxYear => {
              return {value: taxYear, label: taxYear.toString()}
            })
          }, this.uiModelSetOptions);

          this.calcModel.set('taxMunicipality', _.get(metadata, ['taxMunicipalities', 0, 'key'], null));
          this.calcModel.set('taxMunicipalityNames', _.reduce(metadata.taxMunicipalities, (memo, taxMunicipality) => {
            memo[taxMunicipality.key] = taxMunicipality.value;
            return memo;
          }, {}));
          this.modelCol.get('taxMunicipality').set({
            selectOptions: _.map(metadata.taxMunicipalities, taxMunicipality => {
              return {value: taxMunicipality.key, label: taxMunicipality.value}
            })
          }, this.uiModelSetOptions);

          this.updateView(this.calcModel.attributes);
        }, this)
    ).catch((error) =>
        console.error('np sz query for metadata failed', error)
    )

    this.listenTo(this.modelCol, 'change', function (model, args) {
      const inputModelUpdated = _.some(this.inputModelIds, function (id) {
        return model && model.id === id;
      });
      if (inputModelUpdated)
        this.onInputModelChange.apply(this, arguments);
      else if (model.id === 'print-format' || model.id === 'print-lng') {
        // print-options changed, reset print-action
        this.modelCol.get('print-action').set(this.printActionInitialAttr);
      }
    });

    const printActionModel = this.modelCol.get('print-action');
    this.listenTo(printActionModel, 'action', function () {
      if (printActionModel.get('state') !== 'print-request')
        return;
      this._print();
    });

    this.view.render();
    return super.start();
  }

  updateView(attributes) {
    if (attributes) {
      _.each(_.keys(attributes), function (key) {
        const uiModel = this.modelCol.get(key);
        if (uiModel)
          uiModel.set({value: attributes[key]}, this.uiModelSetOptions);
      }, this);
    }
  }

  onInputModelChange(model, args) {
    // don't handle self triggered events (see default value below)
    if (_.isObject(args) && args.updateMode === this.uiModelSetOptions.updateMode)
      return;

    const id = model.get('id');
    this.calcModel.set(id, model.get('value'));

    this.updateView(this.calcModel.changedAttributes());

    function nullToUndefined(val) {
      if (!_.isNull(val))
        return val;
    }

    const calcNpSzOptions = {
      taxYear: this.calcModel.get('taxYear'),
      couple: this.calcModel.get('couple'),
      taxableIncome: nullToUndefined(this.calcModel.get('taxableIncomeCantonal')),
      rateDeterminingIncome: nullToUndefined(this.calcModel.get('rateDeterminingIncomeCantonal')),
      taxableAsset: nullToUndefined(this.calcModel.get('taxableAssetCantonal')),
      rateDeterminingAsset: nullToUndefined(this.calcModel.get('rateDeterminingAssetCantonal')),
      taxMunicipalityId: nullToUndefined(this.calcModel.get('taxMunicipality')),
      confRomCathCount: nullToUndefined(this.calcModel.get('confRomCathCount')),
      confEvRefCount: nullToUndefined(this.calcModel.get('confEvRefCount')),
      confOtherNoneCount: nullToUndefined(this.calcModel.get('confOtherNoneCount'))
    };

    function undefinedToNull(val) {
      if (_.isUndefined(val))
        return null;
      return val;
    }

    const calcNpFederalOptions = {
      taxYear: this.calcModel.get('taxYear'),
      couple: this.calcModel.get('couple'),
      taxableIncome: undefinedToNull(this.calcModel.get('taxableIncomeFederal')),
      rateDeterminingIncome: undefinedToNull(this.calcModel.get('rateDeterminingIncomeFederal')),
      childAndSupportedPersonCount: undefinedToNull(this.calcModel.get('childAndSupportedPersonCount'))
    };

    /** @type NpFederalTaxCalcSvc */
    const npFederalCalculatorSvc = services.calculators['np-federal'];
    /** @type NpSzTaxCalcSvc */
    const npSzCalculatorSvc = services.calculators['np-sz'];
    this.pqueue.add(() => Promise.all([npSzCalculatorSvc.calculateIncomeWealthChurchTax(calcNpSzOptions),
      npFederalCalculatorSvc.calculate(calcNpFederalOptions)]))
        .then(_.bind(([dataNpSz, dataNpFederal]) => {
          const updates = {};
          _.each(this.resultModelIds, function (id) {
            // exclude computed properties without setter
            if (!_.has(this.calcModel.computed, id) || _.has(this.calcModel.computed[id], 'set'))
              updates[id] = null;
          }, this);

          _.each(dataNpSz.results, function (result) {
            switch (result.name) {
              case "taxableIncomeRounded":
                updates.taxableIncomeRoundedCantonal = result.value;
                break;
              case "rateDeterminingIncomeRounded":
                updates.rateDeterminingIncomeRoundedCantonal = result.value;
                break;
              case "simpleIncomeTaxCantonal":
                updates.simpleIncomeTaxCantonal = result.value;
                updates.simpleIncomeTaxRateCantonal = _.get(result, ['details', 'rate'], null);
                break;
              case "simpleIncomeTaxRegular":
                updates.simpleIncomeTaxRegular = result.value;
                updates.simpleIncomeTaxRateRegular = _.get(result, ['details', 'rate'], null);
                break;
              case "taxableAssetRounded":
                updates.taxableAssetRoundedCantonal = result.value;
                break;
              case "rateDeterminingAssetRounded":
                updates.rateDeterminingAssetRoundedCantonal = result.value;
                break;
              case "simpleAssetTax":
                updates.simpleAssetTaxCantonal = result.value;
                updates.simpleAssetTaxRateCantonal = _.get(result, ['details', 'rate'], null);
                break;
              case "simpleTaxRegular":
                updates.simpleTaxRegular = result.value;
                break;
              case "simpleTaxCantonal":
                updates.simpleTaxCantonal = result.value;
                break;
              case "cantonalIncomeTax":
                updates.cantonalIncomeTax = result.value;
                updates.cantonalTaxRate = _.get(result, ['details', 'rate'], null);
                break;
              case "cantonalAssetTax":
                updates.cantonalAssetTax = result.value;
                updates.cantonalTaxRate = _.get(result, ['details', 'rate'], null);
                break;
              case "districtIncomeTax":
                updates.districtIncomeTax = result.value;
                updates.districtTaxRate = _.get(result, ['details', 'rate'], null);
                break;
              case "districtAssetTax":
                updates.districtAssetTax = result.value;
                updates.districtTaxRate = _.get(result, ['details', 'rate'], null);
                break;
              case "municipalityIncomeTax":
                updates.municipalityIncomeTax = result.value;
                updates.municipalityTaxRate = _.get(result, ['details', 'rate'], null);
                break;
              case "municipalityAssetTax":
                updates.municipalityAssetTax = result.value;
                updates.municipalityTaxRate = _.get(result, ['details', 'rate'], null);
                break;
              case "romCathChurchIncomeTax":
                updates.romCathChurchIncomeTax = result.value;
                updates.romCathChurchTaxRate = _.get(result, ['details', 'rate'], null);
                break;
              case "romCathChurchAssetTax":
                updates.romCathChurchAssetTax = result.value;
                updates.romCathChurchTaxRate = _.get(result, ['details', 'rate'], null);
                break;
              case "evRefChurchIncomeTax":
                updates.evRefChurchIncomeTax = result.value;
                updates.evRefChurchTaxRate = _.get(result, ['details', 'rate'], null);
                break;
              case "evRefChurchAssetTax":
                updates.evRefChurchAssetTax = result.value;
                updates.evRefChurchTaxRate = _.get(result, ['details', 'rate'], null);
                break;
              case "totalTax":
                updates.totalTaxCantonal = result.value;
                break;
            }
          }, this);

          _.each(dataNpFederal.results, function (result) {
            switch (result.name) {
              case "taxableIncomeRounded":
                updates.taxableIncomeRoundedFederal = result.value;
                break;
              case "rateDeterminingIncomeRounded":
                updates.rateDeterminingIncomeRoundedFederal = result.value;
                break;
              case "incomeTax":
                updates.incomeTaxFederal = result.value;
                updates.incomeTaxRateFederal = _.get(result, ['details', 'rate'], null);
                break;
              case "incomeTaxReductionChildSuppPers":
                updates.incomeTaxReductionChildSuppPers = result.value;
                updates.incomeTaxReductionChildSuppPersCount = _.get(result, ['details', 'count'], null);
                break;
              case "taxTotal":
                updates.taxTotalFederal = result.value;
                break;
            }
          }, this);

          this.calcModel.set(updates);
          this.updateView(this.calcModel.changedAttributes());

          this.modelCol.get('print-action').set(this.printActionInitialAttr);
        }));
  }

  _print() {
    const printActionModel = this.modelCol.get('print-action');

    printActionModel.set({
      state: 'print-wait',
      text: 'print-button.printing.text',
      icon: 'fas fa-spinner fa-pulse has-text-primary'
    }, this.uiModelSetOptions);

    let exportFormat = "application/pdf";
    let icon = 'far fa-file-pdf has-text-danger';
    if (this.modelCol.get('print-format').get('value') === 'docx') {
      exportFormat = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      icon = 'far fa-file-word has-text-link';
    } else if (this.modelCol.get('print-format').get('value') === 'xlsx') {
      exportFormat = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      icon = 'far fa-file-excel has-text-success';
    }

    const printOptions = {
      exportFormat: exportFormat,
      language: this.modelCol.get('print-lng').get('value'),
      report: 'calc.np-sz',
      datasource: this._createPrintDataSource()
    };

    const self = this;
    this.pqueue.add(() => services.printSvc.print(printOptions))
        .then((blob) => {
          const fileRef = firebase.storage().ref().child(blob);

          // Get the download URL
          fileRef.getDownloadURL().then(function (url) {
            printActionModel.set({
              state: 'print-download',
              href: url,
              icon: icon,
              text: 'print-button.download.text',
              target: '_blank'
            }, self.uiModelSetOptions);
          }).catch(error => {
            console.error('Get download url failed', error);
            printActionModel.set(self.printActionInitialAttr);
          })
        }, error => {
          console.error('Print job failed', error);
          printActionModel.set(self.printActionInitialAttr);
        });
  }

  _createPrintDataSource() {
    const cm = this.calcModel;
    return {
      taxYear: cm.get('taxYear'),
      maritalState: cm.get('maritalState'),
      confRomCathCount: cm.get('confRomCathCount'),
      confEvRefCount: cm.get('confEvRefCount'),
      confOtherNoneCount: cm.get('confOtherNoneCount'),
      childAndSupportedPersonCount: cm.get('childAndSupportedPersonCount'),
      municipality: cm.get('taxMunicipality') ? cm.get('taxMunicipalityNames')[cm.get('taxMunicipality')] : undefined,
      cantonal: {
        taxableIncome: cm.get('taxableIncomeCantonal'),
        rateDeterminingIncome: cm.get('rateDeterminingIncomeCantonal'),
        taxableAsset: cm.get('taxableAssetCantonal'),
        rateDeterminingAsset: cm.get('rateDeterminingAssetCantonal'),
        // incomeTariff: "single",
        simpleTaxResults: [
          {
            description: "calc.np-sz.description.incomeCanton",
            taxable: cm.get('taxableIncomeRoundedCantonal'),
            rateDetermining: cm.get('rateDeterminingIncomeRoundedCantonal'),
            rate: cm.get('simpleIncomeTaxRateCantonal'),
            tax: cm.get('simpleIncomeTaxCantonal')
          },
          {
            description: "calc.np-sz.description.incomeMunicipality",
            taxable: cm.get('taxableIncomeRoundedCantonal'),
            rateDetermining: cm.get('rateDeterminingIncomeRoundedCantonal'),
            rate: cm.get('simpleIncomeTaxRateRegular'),
            tax: cm.get('simpleIncomeTaxRegular')
          },
          {
            description: "calc.npsz.description.asset",
            taxable: cm.get('taxableAssetRoundedCantonal'),
            rateDetermining: cm.get('rateDeterminingAssetRoundedCantonal'),
            rate: cm.get('simpleAssetTaxRateCantonal'),
            tax: cm.get('simpleAssetTaxCantonal')
          }
        ],
        simpleTaxRegular: cm.get('simpleTaxRegular'),
        simpleTaxCantonal: cm.get('simpleTaxCantonal'),
        taxResults: [
          {
            description: "calc.npsz.tbTaxResults.desc.canton",
            rate: cm.get('cantonalTaxRate'),
            incomeTax: cm.get('cantonalIncomeTax'),
            assetTax: cm.get('cantonalAssetTax'),
            tax: cm.get('cantonalTax')
          },
          {
            description: "calc.npsz.tbTaxResults.desc.district",
            rate: cm.get('districtTaxRate'),
            incomeTax: cm.get('districtIncomeTax'),
            assetTax: cm.get('districtAssetTax'),
            tax: cm.get('districtTax')
          },
          {
            description: "calc.npsz.tbTaxResults.desc.municipality",
            rate: cm.get('municipalityTaxRate'),
            incomeTax: cm.get('municipalityIncomeTax'),
            assetTax: cm.get('municipalityAssetTax'),
            tax: cm.get('municipalityTax')
          },
          {
            description: "calc.npsz.tbTaxResults.desc.romCathTax",
            rate: cm.get('romCathChurchTaxRate'),
            incomeTax: cm.get('romCathChurchIncomeTax'),
            assetTax: cm.get('romCathChurchAssetTax'),
            tax: cm.get('romCathChurchTax')
          },
          {
            description: "calc.npsz.tbTaxResults.desc.evRefTax",
            rate: cm.get('evRefChurchTaxRate'),
            incomeTax: cm.get('evRefChurchIncomeTax'),
            assetTax: cm.get('evRefChurchAssetTax'),
            tax: cm.get('evRefChurchTax')
          }
        ],
        taxTotal: cm.get('totalTaxCantonal')
      },
      federal: {
        taxableIncome: cm.get('taxableIncomeFederal'),
        rateDeterminingIncome: cm.get('rateDeterminingIncomeFederal'),
        // tariff: "family",
        results: [
          {
            description: "calc.np-federal.description.income",
            taxable: cm.get('taxableIncomeRoundedFederal'),
            rateDetermining: cm.get('rateDeterminingIncomeRoundedFederal'),
            rate: cm.get('incomeTaxRateFederal'),
            tax: cm.get('incomeTaxFederal')
          },
          {
            description: "calc.np-federal.description.reductionChildSuppPers",
            taxable: null,
            rateDetermining: null,
            count: cm.get('incomeTaxReductionChildSuppPersCount'),
            tax: cm.get('incomeTaxReductionChildSuppPers')
          }
        ],
        taxTotal: cm.get('taxTotalFederal')
      },
      summary: {
        results: [
          {
            description: "calc.npsz.tbSummary.desc.cantonalTax",
            tax: cm.get('totalTaxCantonal')
          },
          {
            description: "calc.npsz.tbSummary.desc.federalTax",
            tax: cm.get('taxTotalFederal')
          }
        ],
        taxTotal: cm.get('taxTotal')
      }
    };
  }

  stop() {
    this.stopListening(this.modelCol);
    return super.stop();
  }

  destroy() {
    if (this.view) {
      this.view.remove();
      this.view = undefined;
    }
    return super.destroy();
  }
}
