import _ from 'underscore';
import Backbone from 'backbone';

export default Backbone.View.extend({

  /**
   * @type Array<Backbone.View>
   */
  childViews: undefined,

  initialize: function () {
    this.childViews = [];
  },

  removeChildViews: function () {
    _.each(this.childViews, function (childView) {
      childView.remove();
    }, this);

    this.childViews.length = 0;
  },

  beforeRemove: function () {
  },

  remove: function () {
    this.removeChildViews();
    this.beforeRemove();
    Backbone.View.prototype.remove.call(this);
  }
});
