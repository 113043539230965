import $ from 'jquery';
import services from "./services";

/**
 * Base class for np tax calculator services
 */
export default class NpTaxCalcSvc {

  setServiceRootPath(rootPath) {
    this.rootPath = rootPath + "/taxcalcSvc";
  }

  /**
   * Return a Promise which resolves the metadata for the given calculator id
   * @param {string} calculatorId the id of the calculator (e.g. 'np-federal')
   *
   * @returns {Promise<object<string, *>>}
   */
  metadata(calculatorId) {
    const self = this;
    return new Promise(
        function (resolve, reject) {
          services.auth.getCurrentUserIdToken().then(function (token) {
            $.ajax({
              url: self.rootPath + '/taxcalc/np/metadata',
              contentType: 'application/json',
              type: 'POST',
              data: JSON.stringify({calculator: calculatorId}),
              cache: false,
              headers: {
                "Authorization": 'Bearer ' + token
              },
              timeout: services.getRequestTimeOut(),
              success: function (data) {
                resolve(data.props);
              },
              error: function (xhr) {
                reject(xhr);
              },
            });
          }, reject)
        }
    );
  }
}
