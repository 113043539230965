import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import i18next from "i18next";
import CollectionView from './collection-view';
import ContainerView from "./container-view";

const NavbarItemLinkView = Backbone.View.extend({

  tagName: 'a',
  className: 'navbar-item',

  render: function () {
    let labelText = this.model.get('label');
    if (labelText)
      labelText = i18next.t(labelText);
    this.$el.html(labelText);

    if (this.model.get('active'))
      this.$el.addClass('is-active');
    else
      this.$el.removeClass('is-active');

    const href = this.model.get('href');
    if (href)
      this.$el.prop('href', href);
    else
      this.$el.removeProp('href');
    return this;
  }
});

const NavbarItemDropdownView = ContainerView.extend({

  tagName: 'div',
  className: 'navbar-item has-dropdown is-hoverable',

  initialize: function () {
    ContainerView.prototype.initialize.call(this, arguments);
    this.listenTo(this.model, 'change', this.render);
  },

  render: function () {
    this.removeChildViews();

    const attributes = {};
    if (this.model.get('href'))
      attributes['nav-id'] = this.model.get('id');

    const mainNavItemLink = new NavbarItemLinkView({
      model: this.model,
      className: 'navbar-link',
      tagName: this.model.get('href') ? 'a' : 'div',
      attributes: attributes
    });
    this.childViews.push(mainNavItemLink);
    this.$el.append(mainNavItemLink.el);

    const $dropdownEl = $('<div class="navbar-dropdown"></div>');
    this.$el.append($dropdownEl);

    // TODO add support for <hr class="navbar-divider">
    const dropDownView = new (CollectionView.extend({
      childView: NavbarItemLinkView,
      childViewOptions: function (childModel) {
        const options = {};
        if(childModel.get('href'))
          options.attributes = { 'nav-id': childModel.get('id') };
        return options;
      }
    }))({el: $dropdownEl[0], collection: this.collection});
    this.childViews.push(dropDownView);

    _.each(this.childViews, function (view) {
      view.render();
    });
    return this;
  }

});

export default CollectionView.extend({
  childView: function (model) {
    if (model.get('childModelCol') && model.get('childModelCol').length > 0)
      return NavbarItemDropdownView;
    else
      return NavbarItemLinkView;
  },

  childViewOptions: function (childModel) {
    const options = {};
    if (childModel.get('childModelCol'))
      options.collection = childModel.get('childModelCol');
    return options;
  }
});
