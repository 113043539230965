import _ from "underscore";
import ContainerView from '../view/container-view';
import NavbarView from '../view/navbar-view';

export default ContainerView.extend({

  events: {
    "click .navbar-burger": "toggleMenu",
  },

  initialize: function (args) {
    ContainerView.prototype.initialize.call(this, arguments);

    const navbarView = new NavbarView({el: this.$el.find('#navbarEnd').get(0), collection: args.navigation});
    this.childViews.push(navbarView);
  },

  toggleMenu: function () {
    this.$el.find(".navbar-burger").toggleClass("is-active");
    this.$el.find(".navbar-menu").toggleClass("is-active");
  },

  render: function () {
    _.each(this.childViews, function (childView) {
      childView.render();
    }, this);

    // this.$el.localize();
    return this;
  },
});
