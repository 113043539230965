import Backbone from 'backbone';
import _ from "underscore";

export default Backbone.View.extend({

  /**
   * @typedef {Function} FuncChildView
   * @param {Backbone.Model} the model for the child view
   * @return {Backbone.View} the child view class
   */

  /** @type {Backbone.View|FuncChildView} the class for the child views or a function which returns the child view class */
  childView: undefined,

  /**
   * @typedef {Function} FuncChildViewOptions
   * @param {Backbone.Model} the model for the child view
   * @return {Object} the additional options for the child view
   */

  /** @type {Object|FuncChildViewOptions} the options for the child views or a function which returns the options for the child view */
  childViewOptions: undefined,

  /** @type {Array<Backbone.View>} */
  _childViews: undefined,

  initialize: function () {
    this._childViews = [];

    this.listenTo(this.collection, {
      'sort': this.render,
      'reset': this.render,
      'update': this.render
    });
  },

  render: function () {
    this.removeChildViews();
    this.collection.each(function (model) {
      const childView = this._createChildView(model);
      this._childViews.push(childView);
      childView.render();
      this.$el.append(childView.el);
    }, this);
    return this;
  },

  buildChildView: function (childModel, ChildViewClass, childViewOptions) {
    const options = _.extend({model: childModel}, childViewOptions);
    return new ChildViewClass(options);
  },

  _getChildViewOptions(childModel) {
    if (_.isFunction(this.childViewOptions)) {
      return this.childViewOptions(childModel);
    }
    return this.childViewOptions;
  },

  _getView(view, childModel) {
    if (view.prototype instanceof Backbone.View || view === Backbone.View) {
      return view;
    } else if (_.isFunction(view)) {
      return view.call(this, childModel);
    }
  },

  _getChildView(childModel) {
    const funcCheckChildView = (childView) => {
      if (!childView) {
        throw new Error({
          name: 'CollectionViewError',
          message: '"childView" must be a view class or a function that returns a view class'
        });
      }
    };

    funcCheckChildView(this.childView);
    const childView = this._getView(this.childView, childModel);
    funcCheckChildView(childView);
    return childView;
  },

  _createChildView(childModel) {
    const ChildView = this._getChildView(childModel);
    const childViewOptions = this._getChildViewOptions(childModel);
    const view = this.buildChildView(childModel, ChildView, childViewOptions);
    return view;
  },

  removeChildViews: function () {
    _.each(this._childViews, function (childView) {
      childView.remove();
    }, this);
    this._childViews.length = 0;
  },

  remove: function () {
    this.removeChildViews();
    Backbone.View.prototype.remove.call(this);
  }
});