import _ from "underscore";
import 'backbone.stickit';
import ContainerView from '../view/container-view';

export default ContainerView.extend({

  template: _.template(require('./login-view.tmpl.html')),

  events: {
    "click #btnLogin": "login",
  },

  bindings: {
    '#email': {
      observe: 'email'
    },
    '#password': {
      observe: 'password'
    },
    '#notification': {
      observe: 'notification',
      classes: {
        'is-hidden': {
          observe: 'notification',
          onGet: function (value) {
            return !value;
          }
        }
      }
    }
  },

  initialize: function () {
    ContainerView.prototype.initialize.call(this, arguments);
  },

  login: function (e) {
    e.preventDefault();
    this.trigger('login');
  },

  render: function () {
    this.$el.html(this.template({}));
    this.stickit();
    this.$el.localize();
    return this;
  },

  beforeRemove: function () {
    this.unstickit();
  }
})
