import _ from "underscore";
import numeral from "numeral";
import nearley from "nearley";
import grammar from "./../parser/simple-expr-parser.js";

function implode(separator, ...strings) {
  return _.filter(strings, s => _.isString(s) && s).join(separator);
}

function numberFormat(number, format) {
  if (_.isNumber(number) && _.isString(format)) {
    try {
      return numeral(number).format(format)
    } catch (error) {
      console.error('failed to format number', error);
    }
  }
}

const functions = {
  implode: implode,
  concat: (...strings) => implode.apply(null, _.union([''], strings)),
  formatNumber: (format, number) => numberFormat(number, format),
  numberFormat: numberFormat
};

export default class SimpleExprEvaluator {
  constructor() {
    this.setExpressionStr(undefined);
  }

  setExpressionStr(expr) {
    this._expressionStr = expr;
    this._ast = [];

    if (this._expressionStr) {
      const parser = new nearley.Parser(nearley.Grammar.fromCompiled(grammar));
      parser.feed(expr);
      this._ast = parser.results;
    }
  }

  _doEval(currentAst, args) {
    const unary_expression = currentAst;
    if (_.isObject(unary_expression)) {
      switch (unary_expression.type) {
        case "function":
          const params = _.map(unary_expression.parameters, function (param) {
            return this._doEval(param, args);
          }, this);
          return functions[unary_expression.name].apply(null, params);
        case "parameter":
          return args[unary_expression.index];
        case "boolean_literal":
        case "number_literal":
        case "string_literal":
        case "null_literal":
          return unary_expression.value;
      }
    }
  }

  eval(...args) {
    return this._doEval(_.first(this._ast), args);
  }
}
