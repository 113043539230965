export default {
  /** @type Object.<string, object> */
  calculators: {},
  /** @type PrintSvc */
  printSvc: undefined,
  /**
   * Returns the default request timeout for the requested service
   *
   * @param {string} serviceName the name of the service
   * @return {number} the timeout for the requests
   */
  getRequestTimeOut: undefined,
  /** @type {object} the auth service */
  auth: undefined,
};
