import _ from "underscore";
import Backbone from 'backbone';
import 'backbone.stickit';
import i18next from "i18next";

export default Backbone.View.extend({

  /**
   * @type {string}
   */
  label: undefined,
  template: _.template(require('./field-select-view.tmpl.html')),

  bindings: {
    select: {
      observe: 'value',
      selectOptions: {
        collection: function () {
          return this.model.get('selectOptions')
        }
      }
    },
    label: {
      observe: 'label',
      onGet: function (value) {
        return i18next.t(value || this.label);
      },
      update: function ($el, val) {
        $el.html(val);
      }
    }
  },

  initialize: function (args) {
    _.extend(this, _.pick(args, ['label']));
  },

  render: function () {
    this.$el.html(this.template({}));
    this.stickit();
    return this;
  },

  remove: function () {
    this.unstickit();
    Backbone.View.prototype.remove.call(this);
  },

});
