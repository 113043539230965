import Backbone from 'backbone';
import 'backbone.stickit';
import i18next from "i18next";

export default Backbone.View.extend({

  initialize: function () {
    this.listenTo(this.model, 'change:href', this.render);
  },

  events: {
    "click a,button": function () {
      this.model.trigger('action', 'user-click');
    },
  },

  bindings: {
    'a,button': {
      observe: ['icon', 'text'],
      update: function ($el, val) {
        const icon = val[0], text = val[1];
        let html = '';

        if (icon)
          html += '<span class="icon"><i class="' + icon + '"></i></span>';
        if (text)
          html += '<span>' + i18next.t(text) + '</span>';
        $el.html(html);
      },
      classes: {
        "is-loading": "isLoading"
      },
      attributes: [
        {
          name: 'disabled',
          observe: "enabled",
          onGet: function (enabled) {
            return enabled === false;
          }
        }
      ]
    },
    'a': {
      attributes: [
        {
          name: 'href',
          observe: 'href'
        },
        {
          name: 'target',
          observe: 'target'
        }
      ]
    }
  },

  render: function () {
    if (this.model.get('href'))
      this.$el.html('<a class="button"></a>');
    else
      this.$el.html('<button class="button"></button>');

    this.stickit();
    return this;
  },

  remove: function () {
    this.unstickit();
    Backbone.View.prototype.remove.call(this);
  }
});
