import _ from 'underscore';
import Backbone from 'backbone';
import DefaultController from "./default-controller";
import ApplicationView from "./../comp-view/application-view";
import services from "../service/services";

export default class ApplicationController extends DefaultController {

  constructor() {
    super();
    _.extend(this, Backbone.Events);

    this.navCalcNpFederal = new Backbone.Model({
      id: 'calcNpFederal',
      label: 'navbar.item.np.calcFederal.label',
      route: 'calculatorNpFederal'
    });
    this.navCalcNpSz = new Backbone.Model({id: 'calcNpSz', label: 'navbar.item.np.calcSz.label', route: 'calculatorNpSz'});
    this.navNpModel = new Backbone.Model({
      id: 'np',
      label: 'navbar.item.np.label',
      childModelCol: new Backbone.Collection([this.navCalcNpFederal, this.navCalcNpSz])
    });

    this.navLogoutModel = new Backbone.Model({id: 'logout', label: 'Logout', route: 'logout'});
    this.navAccountModel = new Backbone.Model({
      id: 'account',
      label: 'account',
      childModelCol: new Backbone.Collection([this.navLogoutModel])
    });

    this.navbarEndCol = new Backbone.Collection();
  }

  /**
   * @param {Object} options the options
   * @param {HTMLElement} options.bodyEl the body element
   * @param {Object} options.router the router
   * @returns {Promise<void>}
   */
  init(options) {
    _.each([this.navLogoutModel, this.navCalcNpFederal, this.navCalcNpSz], function (model) {
      model.set('href', options.router.generate(model.get('route')));
    }, this);
    this.listenTo(services.userModel, 'change', this.onAuthChanged);

    this.view = new ApplicationView({el: options.bodyEl, navigation: this.navbarEndCol});
    return super.init();
  }

  start() {
    this.onAuthChanged();
    this.view.render();
    return super.start();
  }

  onAuthChanged() {
    if (services.userModel.get('authenticated')) {
      this.navAccountModel.set('label', services.userModel.get('user').email);
      this.navNpModel.set('active', true);
      if (!this.navbarEndCol.get('np'))
        this.navbarEndCol.add(this.navNpModel);
      if (!this.navbarEndCol.get('account'))
        this.navbarEndCol.add(this.navAccountModel);
    } else {
      this.navbarEndCol.remove(['np', 'account']);
    }
  }

  stop() {
    this.stopListening(services.userModel);
    return super.stop();
  }
}
