import i18next from 'i18next';
import _ from "underscore";
import Backbone from 'backbone';
import 'backbone.stickit';

export default Backbone.View.extend({

  /**
   * @type {string}
   */
  label: undefined,

  /**
   * Name of the radio input fields
   * @type {string}
   */
  name: undefined,

  template: _.template(require('./field-radio-view.tmpl.html')),

  bindings: {
    'input[type="radio"]': {
      observe: 'value',
    },
    'label.label': {
      observe: 'label',
      onGet: function (value) {
        return i18next.t(value || this.label);
      },
      update: function ($el, val) {
        $el.html(val);
      }
    }
  },

  initialize: function (args) {
    _.extend(this, _.pick(args, ['label']));
    this.name = _.uniqueId('radio_');
  },

  render: function () {
    this.$el.html(this.template({name: this.name, selectOptions: this.model.get('selectOptions')}));
    this.stickit();
    return this;
  },

  remove: function () {
    this.unstickit();
    Backbone.View.prototype.remove.call(this);
  },

});
