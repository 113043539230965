import $ from 'jquery';
import _ from 'underscore';
import i18next from 'i18next';
import jqueryI18next from 'jquery-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import numeral from 'numeral';
import registerNumeralLocales from './plugins/numeral-locales';
import Backbone from 'backbone';
import createCtRouter from './controller/ct-router';
import services from './service/services';
import NpFederalTaxCalcSvc from "./service/np-federal-taxcalc-svc";
import NpSzTaxCalcSvc from './service/np-sz-taxcalc-svc';
import PrintSvc from "./service/print-svc";

const firebaseConfig = require('./../firebaseConfig.json');

export default class App {

  /**
   * @param {Object} options the options
   *
   * @param {String} options.serviceRootPathFunctions the service root path for calculations
   * @param {String} options.serviceRootPathPrintSvc the service root path for print
   * @return {Promise<unknown>}
   */
  initialize(options) {
    return new Promise((resolve, reject) => {
      services.userModel = new Backbone.Model({authenticated: false, user: null});

      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
      firebase.analytics();

      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          // console.log('authenticated', user);
          services.userModel.set({authenticated: true, user: user});
        } else {
          // console.log('not authenticated');
          services.userModel.set({authenticated: false, user: null});
        }
      });

      // wait for complete initializing of auth
      function getCurrentUser() {
        return new Promise((resolve, reject) => {
          const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            unsubscribe();
            resolve(user);
          }, reject);
        });
      }

      getCurrentUser().then(() =>
          i18next.use(LanguageDetector).use(Backend).init({
            backend: {
              // for all available options read the backend's repository readme file
              loadPath: 'i18n/{{lng}}.json', // 'i18n/{{lng}}/{{ns}}.json'
            },
            lng: 'de-CH',
            supportedLngs: ['de-CH'],
            keySeparator: false,
            debug: false
          })).then(() => {

            jqueryI18next.init(i18next, $, {
              tName: 't', // --> appends $.t = i18next.t
              i18nName: 'i18n', // --> appends $.i18n = i18next
              handleName: 'localize', // --> appends $(selector).localize(opts);
              selectorAttr: 'data-i18n', // selector for translating elements
              targetAttr: 'i18n-target', // data-() attribute to grab target element to translate (if different than itself)
              optionsAttr: 'i18n-options', // data-() attribute that contains options, will load/set if useOptionsAttr = true
              useOptionsAttr: false, // see optionsAttr
              parseDefaultValueFromContent: true // parses default values from content ele.val or ele.text
            });

            registerNumeralLocales(numeral);
            numeral.locale('de-CH');

            /**
             * Return the timeout in ms for requests
             * @param {string} serviceName
             * @returns {number}
             */
            services.getRequestTimeOut = serviceName => 3000;

            services.auth = {
              getCurrentUserIdToken: function () {
                return new Promise((resolve, reject) => {
                  const user = firebase.auth().currentUser;
                  if (!user)
                    resolve(null);
                  else {
                    user.getIdToken().then(function (token) {
                      resolve(token);
                    }, reject);
                  }
                });
              }
            };

            services.calculators['np-federal'] = new NpFederalTaxCalcSvc();
            services.calculators['np-sz'] = new NpSzTaxCalcSvc();
            _.each(_.keys(services.calculators), function (key) {
              services.calculators[key].setServiceRootPath(options.serviceRootPathFunctions);
            })

            services.printSvc = new PrintSvc();
            services.printSvc.setServiceRootPath(options.serviceRootPathPrintSvc);

            this.router = createCtRouter(window.document.body);
            resolve();
          }
      ).catch((error) => {
        reject(error);
      });
    });
  }

  start() {
    return new Promise((resolve, reject) => {
      try {
        this.router.listen();
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

}
